@import 'Variables.scss';
@import 'Allocation.scss';

$slider-track-thickness: 4px;
$slider-track-color: $secondary-grey-2;

$slider-thumb-width: 48px;
$slider-thumb-height: 48px;

$slider-inverval-width: $slider-thumb-width;
$slider-inverval-height: $slider-thumb-height;

@mixin position-on-slider($percentage, $elem-width-px) {
    left: calc((1% * #{$percentage}) - (#{$percentage} / 100 * #{$elem-width-px}));
}

.slider-input {
    height: $slider-thumb-height;
    display: block;

    .range-slider {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .intervals-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
             //z-index: 201;

            .interval {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: $slider-thumb-width;
                height: $slider-thumb-height;
                line-height: $slider-thumb-height;
                background-color: $white;
                z-index: 150;
                cursor: pointer;

                &::after {
                    content: attr(data-interval-percent);
                    display: block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: $font-size-small;
                    text-align: center;
                    border-radius: 50%;
                    border: 1px solid $slider-track-color;
                }
            }
        }

        input {
            &[type='range'] {
                -webkit-appearance: none;
                width: 100%;
                height: $slider-track-thickness;
                background: $slider-track-color;
                outline: none;
                cursor: pointer;
                display: flex;
                align-items: center;

                &::before {
                    content: attr(data-percentage) '%';
                    display: block;
                    position: absolute;
                    width: $slider-thumb-width;
                    height: $slider-thumb-height;
                    line-height: calc(#{$slider-thumb-height} + 1px);
                    text-align: center;
                    border-radius: 50%;
                    z-index: 201;
                    top: 0;
                }

                &::after {
                    content: '';
                    display: block;
                    height: $slider-track-thickness;
                    z-index: 100;
                    position: absolute;
                    left: 0;
                }

                &::-webkit-slider-runnable-track {
                    z-index: 200;
                }

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: $slider-thumb-width;
                    height: $slider-thumb-height;
                    cursor: pointer;
                    opacity: 0;
                }
            }
        }

        @for $i from 0 through 100 {
            .intervals-container {
                .interval {
                    &[data-interval-percent='#{$i}'] {
                        @include position-on-slider($i, $slider-thumb-width);
                    }
                }
            }

            input {
                &[type='range'] {
                    &[data-percentage='#{$i}'] {
                        &::after {
                            width: calc(1% * #{$i});
                            background-color: get-allocation-percentage-bg($i);

                            .color-blind-option & {
                                background-color: get-allocation-percentage-bg-color-blind($i);
                            }
                        }

                        &::before {
                            @include position-on-slider($i, $slider-thumb-width);
                            background-color: get-allocation-percentage-bg($i);
                            color: get-allocation-percentage-fg($i);

                            .color-blind-option & {
                                background-color: get-allocation-percentage-bg-color-blind($i);
                                color: get-allocation-percentage-fg-color-blind($i);
                            }
                        }
                    }
                }
            }
        }
    }
}
