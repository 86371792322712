﻿@import 'Variables';

.error-content {
    text-transform: uppercase;
    margin: (6 * $grid-size) 25% 0 257px;

    h1,
    p {
        font-family: $font-family-ultra-light;
        padding: 0 ($grid-size / 2);
    }

    p {
        background-color: $primary-yellow-1;
        font-size: (2 * $grid-size);
        line-height: (3 * $grid-size);
    }

    h1 {
        background-color: $black;
        color: $white;
        margin-bottom: $border-size;
        font-size: (4 * $grid-size);
    }

    button {
        &.back {
            border: 0;
            background-color: $primary-yellow-1;
            padding: 0 16px 0 32px;
            text-transform: uppercase;
            margin-top: (4 * $grid-size);
            height: 48px;
            font-size: 16px;
            line-height: 48px;
            text-align: left;
            background-image: url('Images/32px-icon_left.svg');
            background-repeat: no-repeat;
            background-position: left center;
            cursor: pointer;
        }
    }
}
