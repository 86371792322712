﻿@import 'Variables';

@mixin placeholder($placeholder-color) {
    &:-ms-input-placeholder { // IE 10+
        color: $placeholder-color !important;
    }

    &::placeholder { // Firefox 18-
        color: $placeholder-color;
    }
}
