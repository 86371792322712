﻿@import 'Variables';

@mixin margin($d, $i) {
    $suffix: unquote(#{$d}-#{$i}x);

    .margin-#{$suffix} {
        $margin: $grid-size * $i;

        @if $d == top {
            margin-top: $margin;
        }
        @else if $d == right {
            margin-right: $margin;
        }
        @else if $d == bottom {
            margin-bottom: $margin;
        }
        @else if $d == left {
            margin-left: $margin;
        }
    }
}

@mixin padding($d, $i) {
    $suffix: unquote(#{$d}-#{$i}x);

    .padding-#{$suffix} {
        $padding: $grid-size * $i;

        @if $d == top {
            padding-top: $padding;
        }
        @else if $d == right {
            padding-right: $padding;
        }
        @else if $d == bottom {
            padding-bottom: $padding;
        }
        @else if $d == left {
            padding-left: $padding;
        }
    }
}

* {
    &,
    &::before,
    &::after,
    button {
        box-sizing: border-box !important;
        font-family: $font-family;
        font-size: $font-size-normal;
        font-style: normal;
        font-weight: 400;
        color: $black;
        user-select: none;
        outline: none;
        -webkit-tap-highlight-color: $tap-highlight-colour;
        text-size-adjust: none;
    }
}

html,
body,
.application-host,
.durandal-wrapper {
    background: $white;
    padding: 0;
    margin: 0;
    height: 100%;
    min-width: $layout-min-width;
    user-select: none;
    cursor: default;
}

body {
    &.overflow-hidden {
        overflow: hidden;
    }

    &.disable-scrolling {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .reset-sticky {
        top: 0 !important;
    }
}

.page-host {
    margin-top: $grid-size * 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

h1 {
    font-size: $font-size-x-large;
}

p {
    @include copy-text;
    margin: 0;
}

.relative-container {
    position: relative;
}

.block {
    display: block;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.bg-secondary-grey-3 {
    background-color: $secondary-grey-3;
}

.confirmation-box {
    margin: 32px 0;
    color: $white;
    padding: 16px;
    background-color: $secondary-grey-1;
}

.button-help-text {
    float: right;
    text-transform: uppercase;
    line-height: 64px;
    text-align: right;
    padding-right: 16px;
    color: $secondary-grey-1;
    opacity: 0;
    transition: opacity .3s, width .2s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.visible {
        opacity: 1;
    }
}

.docked-panel {
    box-shadow: $docked-box-shadow-color 0 0 ($grid-size * 1.5);
}

.sticky-header-table {
    position: sticky;
    top: 0;
    z-index: 10;
}

$dimensions: top right bottom left;

@for $i from 0 through 6 {
    .margin-#{$i}x {
        margin: $grid-size * $i;
    }

    @each $d in $dimensions {
        @include margin($d, $i);
    }

    .padding-#{$i}x {
        padding: $grid-size * $i;
    }

    @each $d in $dimensions {
        @include padding($d, $i);
    }
}
