﻿@import 'Variables';

$icons: alert calendar chat city clock cog design edit /*gemstones*/ hand images info item-attachments location locked mail markings menu paperclip user phone /*clipboard*/ view file users briefcase spanner key /*check-in check-out work-queue*/ home star /*valuation-item valuation*/ search /*invoicing-awaiting invoicing-create invoicing-cancel invoicing*/;

@mixin icon {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 6px;
    background: no-repeat center center;
    width: 32px;
    height: 64px;
}

@mixin icon-set($color-string: $white-string) {
    @each $icon in $icons {
        &.#{$icon} {
            &::before {
                background-image: unquote('url(Images/' + $color-string + '/32px-icon_' + $icon + '.svg)');
            }
        }
    }
}

.menu-panel {
    padding: $grid-size $grid-size $grid-size ($grid-size / 2);

    > a,
    > span {
        display: block;
        position: relative;
        padding: 0 ($grid-size * 2) 0 40px;
        line-height: $grid-size * 2;
        color: $white;
        white-space: nowrap;
        text-decoration: none;

        &.wrap {
            padding-top: 4px;
            padding-bottom: 4px;
            line-height: $copy-text-line-height;
            white-space: normal;
        }

        &.disabled {
            color: $secondary-grey-1;
        }

        &.with-icon {
            &::before {
                position: absolute;
                top: 0;
                left: 6px;
                width: 32px;
                height: 32px;
                content: '';
                background: no-repeat center center;
            }

            @include icon-set($primary-grey-1-string);

            &.back {
                &::before {
                    background-image: unquote('url(Images/' + $primary-grey-1-string + '/32px-icon_left.svg)');
                }
            }

            &.selected {
                &::before {
                    background-image: unquote('url(Images/' + $white-string + '/32px-icon_tick.svg)');
                }
            }
        }
    }

    .menu-panel__title {
        color: $white;
    }

    .menu-panel__title--deactivated {
        color: $secondary-grey-6;
    }

    .menu-panel__subtext {
        color: $secondary-grey-6;
    }
}

.main-menu-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0 ($grid-size / 2) 0 $grid-size;
    vertical-align: top;

    &.with-icon {
        padding-left: 40px;

        &::before {
            @include icon;
        }

        @include icon-set($black-string);
    }

    > a,
    > .link,
    > div {
        &:first-child {
            line-height: 64px;
            color: $black;
            text-decoration: none;
        }
    }

    .menu-panel {
        display: none;
        min-width: 209px;
        position: absolute;
        top: 0;
        left: 0;
        background: $secondary-grey-3;
        cursor: default;
        z-index: 205;
        padding: 0 0 $grid-size;
        max-height: calc(100vh - 32px);
        overflow-y: auto;

        .menu-panel-divider {
            display: block;
            left: 40px;
            width: calc(100% - 40px);
            border-bottom: 1px solid $secondary-grey-4;
            margin: 16px 0 16px 40px;
        }

        > div {
            &:first-child {
                height: 64px;
                margin: 0 0 $grid-size;
                padding: 0 16px 0 40px;
                line-height: 64px;
                color: $primary-yellow-1;
                cursor: pointer;

                &::before {
                    display: block;
                    position: absolute;
                    top: 63px;
                    left: 40px;
                    right: 0;
                    width: auto;
                    padding: 0 16px 0 40px;
                    border-bottom: 1px solid $secondary-grey-4;
                    content: '';
                }

                &::after {
                    @include icon;
                }

                @each $icon in $icons {
                    &.#{$icon} {
                        &::after {
                            background-image: unquote('url(Images/' + $primary-yellow-1-string + '/32px-icon_' + $icon + '.svg)');
                        }
                    }
                }
            }
        }
    }

    &.has-search {
        .menu-hover-panel {
            min-width: 245px;
        }

        .menu-panel {
            .menu-search-contain {
                position: relative;
                display: flex;
                justify-content: space-between;
                margin: -($grid-size / 2) 0 $grid-size;

                .menu-search {
                    color: $primary-grey-1;
                    position: relative;
                    background: none;
                    padding: 0 ($grid-size * 2) 0 0;
                    border-bottom-color: $secondary-grey-4;
                    transition: border-bottom-color .25s ease-in-out;
                    width: calc(100% - 39px);

                    &:focus {
                        border-bottom-color: $white;
                    }

                    &::placeholder {
                        color: $white;
                    }
                }

                .menu-search-icon {
                    margin-left: 6px;
                    display: inline-block;
                    height: $grid-size * 2;
                    width: $grid-size * 2;
                    background: url('Images/32px-icon_search-light.svg') no-repeat scroll 0 center rgba(0, 0, 0, 0);
                }

                .menu-search-clear {
                    position: absolute;
                    top: -2px;
                    right: 0;
                    height: $grid-size * 2;
                    width: $grid-size * 2;
                    cursor: pointer;
                    background: url('Images/32px-icon_cross-light.svg') no-repeat scroll 0 center rgba(0, 0, 0, 0);

                    &:hover {
                        background-color: $secondary-grey-1;
                    }
                }

                .clear-disabled {
                    opacity: 0;
                    cursor: default;
                }
            }

            > div:first-child {
                &.menu {
                    margin-bottom: 0;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    &.active {
        .menu-panel {
            display: block;
        }
    }
}

.main-menu-flyout {
    padding: 0 0 0 16px;

    hr {
        content: '';
        display: block;
        margin: 0 0 0 40px;
        border-top: 0;
        border-bottom: 1px solid $secondary-grey-4;
        border-left: 0;
        border-right: 0;
    }

    .main-menu-flyout-options {
        padding: $grid-size 0;

        > a,
        > .link {
            color: $white;
            border: 0;
        }

        > a,
        > .link,
        > span,
        > p {
            position: relative;
            display: block;
            padding: 4px 32px 4px 40px;
            text-decoration: none;
            line-height: $copy-text-line-height;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 32px;
                background-repeat: no-repeat;
                background-position: left center;
                content: '';
            }
        }
    }
}
