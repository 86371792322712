﻿@import 'Variables';
@import 'Placeholder';

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    // Add min-width:0 to resolve initially (on page load) large input values expanding the fieldset element
    min-width: 0;

    .field {
        font-size: 0;
        line-height: 0;

        .field-label {
            display: inline-block;
            width: 25%;
            min-height: 50px;
            padding: 12px 16px 16px 0;
            vertical-align: top;

            label {
                color: $black;
                line-height: 22px;
            }
        }

        .field-holder {
            display: inline-block;
            color: $placeholder-color;
            margin-bottom: $grid-size;
            width: 75%;
            vertical-align: top;
            padding: 0;

            &.full-width {
                width: 100%;
            }

            > label {
                display: block;
                padding: 12px 16px 12px 8px;
                line-height: 22px;
            }

            > input,
            > textarea {
                margin-top: 8px;
                margin-bottom: 8px;
            }

            &.no-margin {
                margin: 0;
            }

            &.copy-text {
                @include copy-text;
            }
        }
    }
}

input {
    @include placeholder($placeholder-color);

    &[type='text'],
    &[type='password'],
    &[type='number'],
    &[type='tel'],
    &[type='url'],
    &[type='email'],
    &[type='search'],
    &[type='button'] {
        background: none;
        color: $black;
        padding: 0 0 0 8px;
        margin: 0;
        border: 0;
        border-bottom: 2px solid $border-color;
        border-radius: 0;
        line-height: $grid-size * 2;
        text-align: left;
        width: 100%;
        height: $grid-size * 2;
        background-color: $primary-grey-1;
        appearance: textfield;

        &:focus {
            outline: 0;
        }

        &:not([readonly]) {
            &:focus {
                border-bottom-color: $primary-red-1;
            }
        }

        &.input-validation-error {
            border-bottom-color: $validation-color;
        }

        &.with-information-message {
            width: calc(60% - 32px);
        }
    }

    &[type='button'] {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &[type='checkbox'] {
        &.visually-hidden {
            ~ label {
                &::after {
                    position: absolute;
                    top: 0;
                    right: 1px;
                    width: $grid-size * 2;
                    height: $grid-size * 2;
                    border-radius: 0;
                    box-shadow: 0 0 0 1px $secondary-grey-3;
                    background: no-repeat center;
                    content: '';
                }

                &:active {
                    &::after {
                        box-shadow: 0 0 0 1px $primary-grey-1;
                    }
                }
            }

            &:focus {
                ~ label {
                    &::after {
                        box-shadow: 0 0 0 1px $primary-grey-1;
                    }
                }
            }

            &:checked {
                ~ label {
                    &::after {
                        background-image: url('Images/32px-icon_tick.svg');
                    }
                }
            }

            &.input-validation-error {
                ~ label {
                    &::after {
                        box-shadow: 0 0 0 1px $validation-color;
                    }
                }
            }
        }
    }

    &[type='search'] {
        background: unquote('url(Images/64px-icon_search.svg)') no-repeat scroll 5px center $primary-grey-1;
        padding-left: 4 * $grid-size;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    &.large {
        height: 4 * $grid-size;
        font-family: $font-family-light;
        font-size: $grid-size * 2;
        line-height: $grid-size * 2;
    }

    &[readonly] {
        color: $black;
        border-bottom-color: transparent;
        line-height: 32px;
    }

    &.overflow-hidden {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
    }

    &.pointer-events-disabled {
        pointer-events: none;
    }
}

textarea {
    @include placeholder($placeholder-color);
    @include copy-text;
    background: none;
    color: $black;
    padding: 4px 0 0 8px;
    margin: 0;
    border: 0;
    border-bottom: 2px solid $border-color;
    border-radius: 0;
    width: 100%;
    height: $grid-size * 2;
    background-color: $primary-grey-1;
    appearance: none;
    resize: none;
    outline: none;
    min-height: 32px;
    overflow: hidden;

    &:focus {
        border-bottom-color: $primary-red-1;
        outline: 0;
        min-height: $grid-size * 6;
    }

    &.no-autosize {
        &:focus {
            min-height: 32px;
        }
    }
}
