﻿@import 'Variables';

.pager-row {
    position: relative;
    height: 32px;

    &.inline {
        display: inline-block;
        position: relative !important;
        float: left;

        &.right {
            float: right;
        }
    }

    .pager {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0;
        color: $white;
        line-height: 0;
        height: 32px;

        &.position-left {
            left: 0;
            right: auto;
        }

        &:hover {
            border-color: $secondary-grey-2;
        }

        .pager-button {
            display: inline-block;
            background-position: center center;
            background-repeat: no-repeat;
            color: $black;
            background-color: $primary-yellow-1;
            cursor: pointer;
            float: left;
            line-height: 2 * $grid-size;
            text-align: center;
            height: 2 * $grid-size;
            min-width: 32px;
            vertical-align: middle;

            &.previous-page {
                background-size: 32px 32px;
                background-image: url('Images/64px-icon_left.svg');
            }

            &.next-page {
                background-size: 32px 32px;
                background-image: url('Images/64px-icon_right.svg');
            }

            &.current-page {
                background-color: $white;
                padding: 0 14px;
            }

            &.disabled {
                cursor: default;
                pointer-events: none;
            }
        }

        .page-picker {
            display: none;
            width: 85px;
            z-index: 9999;

            .panel-arrow {
                background-image: url('Images/panel-triangle_down.svg');
            }

            &.hover-panel {
                position: absolute;
                background-color: $secondary-grey-3;
                cursor: default;
                padding: 8px;
                width: 100px;
                min-width: initial;
                min-height: 50px;
            }

            input {
                &[type='text'] {
                    background-color: $secondary-grey-3;
                    color: $white;
                    padding: 0 9px;
                    margin: 0;
                    border: 0;
                    line-height: 28px;
                    text-align: center;
                    width: 100%;
                    height: 32px;

                    &:focus {
                        outline: 0 none;
                    }
                }
            }
        }
    }
}

.button-array {
    .pager-row {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        width: auto;
        white-space: nowrap;

        &.margin-bottom-2x {
            margin-bottom: $grid-size * 2;
        }

        .pager {
            position: static;
        }
    }
}
