﻿@import 'Variables';

@mixin alternative-listing-container {
    background-color: $primary-grey-1;
    margin-bottom: #{$border-size};
    min-height: (3 * $grid-size);
    line-height: (3 * $grid-size);
    padding-left: $grid-size;
    display: inline-block;
}

.listing-container {
    position: relative;
}

.listing {
    width: 100%;
    border-spacing: 0;
    table-layout: fixed;
    user-select: text;

    thead {
        tr {
            th {
                @include copy-text-small;
                color: $black;
                padding: 0 20px 4px 0;
                height: ($grid-size * 2) - 1;
                vertical-align: bottom;

                &.sortable {
                    &,
                    label {
                        cursor: pointer;
                    }

                    &:active {
                        background-color: $secondary-grey-1;
                    }

                    .sort-icon {
                        font-size: $font-size-normal;
                        color: $primary-grey-1;
                        padding-right: 4px;
                    }
                }

                span {
                    @include copy-text-small;
                    color: $primary-grey-1;
                    display: inline-block;

                    &.info-icon {
                        float: left;
                        background-image: url('Images/32px-icon_info.svg');
                        background-position: -7px -7px;
                        background-repeat: no-repeat;
                        width: 20px;
                        height: 18px;
                    }
                }
            }
        }
    }

    tr {
        %active-cell {
            background-color: $secondary-grey-1;
            color: $white;
            border-top-color: $secondary-grey-2;
        }

        th,
        td {
            @include copy-text;
            margin: 0;
            text-align: left;
            vertical-align: top;

            &:first-child {
                border-top: 0;
                border-bottom: 0;
            }
        }

        td {
            background-color: $white;
            padding: 4px 20px 3px 0;
            border-top: 1px solid $secondary-grey-2;

            &.wrap {
                height: auto;
                white-space: normal;
                word-wrap: break-word;
            }

            &.truncate {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            span {
                &.indicator {
                    margin-right: 8px;
                    display: inline-block;
                    border-radius: 100%;
                    width: 12px;
                    height: 12px;
                    vertical-align: -1px;
                }

                &.highlight {
                    display: block;
                    padding: 0 8px;
                    background-color: $secondary-grey-1;
                    color: $white;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &.icon-cell {
                width: 32px;

                &.cross {
                    background-image: url('Images/32px-icon_cross.svg');
                    background-repeat: no-repeat;
                    background-position: -10px center;
                }

                &.menu {
                    background-image: url('Images/32px-icon_menu.svg');
                    background-repeat: no-repeat;
                    background-position: -10px center;
                }

                &.dragable-cell {
                    cursor: move;
                }

                &.clickable-cell {
                    cursor: pointer;
                }
            }
        }

        &:last-child,
        &.with-bottom-border {
            td {
                border-bottom: 1px solid $secondary-grey-2;

                &:first-child {
                    border-bottom: 0;
                }
            }
        }

        &.selectable {
            td {
                cursor: pointer;
                user-select: none;

                .status {
                    padding-left: 1px;
                }
            }

            &:active {
                td {
                    @extend %active-cell;
                }
            }
        }

        &.selected {
            &:first-child {
                &:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }

            &:last-child,
            &.with-bottom-border {
                td {
                    border-bottom: 0;
                }
            }

            td,
            th {
                &.check {
                    background-image: unquote('url(Images/' + $white-string + '/32px-icon_tick.svg)');
                    background-repeat: no-repeat;
                }

                a {
                    color: $white;
                }
            }

            %selected-cell {
                background-color: $secondary-grey-1;
                color: $white;
                border-top-color: $secondary-grey-1;

                span {
                    color: $white;

                    &.status {
                        &::before {
                            margin-bottom: -1px;
                            margin-left: -1px;
                            border: 1px solid $white;
                            width: 14px;
                            height: 14px;
                        }

                        &.none {
                            &::before {
                                border: 0;
                            }
                        }
                    }
                }
            }

            td {
                @extend %selected-cell;

                &.icon-cell {
                    &.cross {
                        background-image: unquote('url(\'Images/' + $white-string + '/32px-icon_cross.svg\')');
                    }

                    &.menu {
                        background-image: unquote('url(\'Images/' + $white-string + '/32px-icon_menu.svg\')');
                    }
                }
            }

            &:hover {
                td {
                    @extend %selected-cell;
                }
            }

            &:active {
                &:hover {
                    td {
                        @extend %active-cell;
                    }
                }

                td {
                    @extend %active-cell;
                }
            }
        }
    }

    thead,
    tbody {
        &.no-wrap {
            th,
            td {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    col {
        &.check {
            width: 32px;
        }
    }

    &.with-padding {
        tr {
            th,
            td {
                &:first-child {
                    padding-left: 32px;
                }

                &:last-child {
                    padding-right: 32px;
                }
            }
        }
    }

    &.normal-padding-right {
        tr {
            th,
            td {
                &:last-child {
                    padding-right: 16px;
                }
            }
        }
    }

    &.without-borders {
        width: calc(100% - 16px);

        .ie9 & {
            width: 100%;
        }

        tr {
            td {
                border: 0;
            }
        }
    }

    &.no-check {
        tr {
            th,
            td {
                &:first-child {
                    padding-left: 32px;
                    width: auto;
                }
            }
        }
    }

    &.with-information-message {
        margin-top: 96px;
    }

    &.alternative {
        tr {
            @include alternative-listing-container;
            border-left: 3px solid $secondary-grey-1;
            width: 100%;

            &.selectable {
                cursor: pointer;

                &.selected,
                &:active {
                    background-color: $secondary-grey-1;
                }
            }

            > td {
                line-height: (3 * $grid-size);
                height: 100%;
                display: inline-block;
                padding: 0;
                background-color: transparent;
                width: calc(100% - (3 * #{$grid-size}) - #{$border-size});

                &.full-width {
                    width: 100%;
                }
            }
        }

        &.dark {
            tr {
                background-color: $secondary-grey-1;
                border-left: 0;

                > td {
                    color: $primary-yellow-1;
                    background-color: transparent;
                }

                &.selectable {

                    &.selected,
                    &:active {
                        background-color: $primary-yellow-1;

                        > td {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

.empty-list {
    font-size: $font-size-large;
    font-family: $font-family-light;
    background-color: $white;
    color: $secondary-grey-1;
    text-align: center;
    padding-top: ($grid-size * 4);
    padding-bottom: ($grid-size * 4);

    &.short {
        padding-top: $grid-size * 2;
        padding-bottom: $grid-size * 2;
    }

    &.padding-top-1x {
        padding-top: $grid-size * 1;
    }

    &.padding-bottom-1x {
        padding-bottom: $grid-size * 1;
    }

    &.padding-top-2x {
        padding-top: $grid-size * 2;
    }

    &.padding-bottom-2x {
        padding-bottom: $grid-size * 2;
    }

    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.no-border {
        border: 0;
    }
}
