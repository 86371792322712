﻿@import 'Variables';

$panel-triangle: 'panel-triangle_';
$panel-arrow-directions: up right down left;

@function panel-arrow-image-url($color, $prefix, $arrow-direction) {
    @return unquote('url(Images/' + $prefix + $arrow-direction + '.svg)');
}

.hover-panel {
    position: absolute;
    background-color: $secondary-grey-3;
    min-width: 100px;
    z-index: 100;

    &.scrollable {
        @include overflow-scrolling-touch;
        overflow-y: auto;
    }

    &.with-padding {
        padding: 32px;
    }

    &.square-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.primary-grey {
        background-color: $primary-grey-1;
        color: $white;
        border: 1px solid $primary-grey-1;
    }

    p {
        padding: 16px 0;
    }

    .field-set {
        textarea {
            height: 100%;
        }
    }

    .separator-text {
        color: $secondary-grey-1;
    }

    .panel-arrow {
        position: absolute;
        top: -15px;
        left: calc(50% - 16px);
        background-image: url('Images/panel-triangle_up.svg');
        background-position: center;
        background-repeat: no-repeat;
        width: 48px;
        height: 16px;

        &.left,
        &.right {
            width: 16px;
            height: 32px;
        }
    }

    &.no-arrow {
        .panel-arrow {
            display: none !important;
        }
    }

    @each $panel-arrow-direction in $panel-arrow-directions {
        .panel-arrow {
            &.#{$panel-arrow-direction} {
                background-image: panel-arrow-image-url($white-string, $panel-triangle, $panel-arrow-direction);

                &.bg-primary-grey-1 {
                    background: none;
                    background-image: panel-arrow-image-url($primary-grey-1-string, $panel-triangle, $panel-arrow-direction);
                }
            }
        }
    }
}

.field-holder {
    &.prevent-autocomplete-overlap {
        .awesomplete {
            z-index: 99 !important;
        }
    }
}

.awesomplete {
    position: absolute;
    width: 100%;
    z-index: 100;

    & > ul {
        background-color: $white;
        padding: 10px 0 $grid-size;
        margin-top: $grid-size;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 416px;
        min-width: 100px;
        z-index: 100;

        &[hidden],
        &:empty {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 2 * $grid-size;
            left: calc(37.5% - 16px);
            // TODO: missing icon
            // background-image: url('Images/triangle-shadow_up.svg');
            background-position: center;
            background-repeat: no-repeat;
            width: $grid-size * 2;
            height: $grid-size;
        }

        & > li {
            position: relative;
            cursor: pointer;
            padding: 4px $grid-size;

            &[aria-selected='true'] {
                background-color: $primary-grey-1;
                color: $white;

                mark {
                    color: $white;
                }
            }

            &:hover {
                background: $secondary-grey-1;
                color: $black;
            }
        }
    }

    mark {
        font-weight: 700;
        background-color: inherit;
    }
}
