﻿@import 'Variables';

.operative-days {
    display: initial;
    .operative-days-header {
        font-size: 0;
        // overflow: hidden;
        height: 8 * $grid-size;
        position: sticky;
        top: 0;
        z-index: 101;
        background-color: $white;
        padding: 2 * $grid-size;
        display: flex;

        .week-selector {
            min-width: 413px;
        }

        .button-help-text {
            width: calc(100% - 64px - 413px);

            &.with-cancel-button {
                width: calc(100% - 157px - 413px);
            }

            &.with-no-button {
                padding-right: 0;
                width: calc(100% - 413px);
            }

            span {
                color: $secondary-grey-1;
            }

            .button-help-text--bold {
                font-weight: 600;
            }

            .header-mode {
                font-weight: 600;
                background-color: $primary-grey-1;
                color: $black;
                line-height: 64px;
                padding: 0 16px;
                display: inline-block;
                height: 64px;
                position: relative;

                &.inspector {
                    background-color: $black;
                    color: $white;
                }
            }

            .spacer {
                display: inline-block;
                width: 8px;
            }
        }

        .buttons-ctnr {
            float: right;
            min-width: 64px;

            .assign-operatives,
            .operative-day-overview {
                &.white-with-border {
                    background-image: none;
                    width: 128px;
                    border: 2px solid $secondary-grey-5;
                }
            }

            .assign-operatives {
                background-image: unquote('url(Images/64px-icon_inspect.svg)');
                width: 60px;
                transition: width .2s;
                border: 2px solid $primary-yellow-1;
            }
        }
    }
}
