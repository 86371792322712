﻿@import 'Variables';

.task-tab-menu {
  display: inline-block;
  margin-bottom: (2 * $grid-size);
  height: 4 * $grid-size;
  line-height: 4 * $grid-size;

  &.disabled {
    &,
    button {
      cursor: default;
    }
  }

  button,
  .section-title,
  span {
    border: 0;
    outline: none;
    padding: 0;
    background-color: transparent;
    font-size: $font-size-header;
    font-family: $font-family-ultra-light;
    color: $secondary-grey-2;
    text-transform: uppercase;
    letter-spacing: -2px;
    width: auto;

    &:not(:first-child) {
      margin-left: $grid-size;
    }

    &.selected {
      color: $black;
    }
  }

  @media screen and (max-width: 1136px) {
    &.collapsible {
      button,
      span {
        margin-left: 0;
      }
    }
  }
}

.events-tab-section {
  border-bottom: 1px solid #ccc;
  padding-bottom: 32px;

  .events-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .show-more-btn-wrapper {
      margin-top: 8px;
      margin: 16px auto 0 auto;
    }
    .events {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .info-row {
        display: flex;
        flex-wrap: nowrap;
        height: 30px;

        .event {
          width: 12px;
          height: 100%;
          margin-right: 1px;
        }

        .event-title {
          display: flex;
          align-items: center;
          flex: 1;
          color: #fbec00;
          font-size: 12px;
          font-weight: 500;
          height: 100%;
          background: #5a5a5a;
          padding: 0 12px;
        }

        .event-button {
          height: 100%;
          line-height: normal;
          background-size: 100%;
          font-size: 12px;
          font-weight: 700;
          margin-left: 1px;
        }

        .event-textButton {
          padding: 0 24px;
          width: 100px;
        }

        .event-iconButton {
          width: 30px;
          height: 100%;
        }

        .event-textButton-disabled,
        .event-iconButton-disabled {
          cursor: pointer !important;
        }
      }
    }
  }
}
