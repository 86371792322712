﻿@import 'Variables';

@mixin task-button {
    float: right;
    height: (3 * $grid-size);
    width: (3 * $grid-size);
    min-width: (3 * $grid-size);
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $primary-yellow-1;
    cursor: pointer;
    border: 0;
}

@mixin details-row {
    margin-bottom: #{$border-size};
    min-height: (3 * $grid-size);
    line-height: (3 * $grid-size);
    padding-left: $grid-size;
}

@mixin task-details-container {
    @include details-row;
    display: inline-block;
}

global-tasks,
exception-tasks {
    .listing {
        tr {
            .task-assignment {
                @include task-button;
                border-left: $border-size solid $white;
                background-image: unquote('url(Images/32px-icon_plus.svg)');
            }
        }

        &.alternative {
            &.dark {
                tr {
                    .task-assignment {
                        @include task-button;
                    }
                }
            }
        }
    }
}
