﻿@import 'Variables';
@import 'Tasks';

.project-tasks,
.assignments-overview {
    .hierarchy-with-children {
        & > .project-hierarchy {
            margin-left: $grid-size;
            width: calc(100% - #{$grid-size});
        }
    }

    .hierarchy-with-children--depth-odd {
        background-color: $primary-grey-1;
    }

    .hierarchy-with-children--depth-even {
        background-color: $secondary-grey-2;
    }

    .project-hierarchy {
        display: inline-block;
        width: 100%;
        line-height: 0;
        position: relative;

        &.no-margin-top {
            margin-top: 0 !important;
        }

        & + .project-hierarchy {
            margin-top: $grid-size;
        }

        .project-task-hierarchy {
            @include task-details-container;
            padding-left: 0;
            position: sticky;
            background-color: $primary-grey-1;
            width: calc(100% - (3 * #{$grid-size}));
            margin-bottom: 0;

            &.has-even-depth-parent {
                background-color: $secondary-grey-2;
            }

            &.full-width {
                padding-left: 16px;
                width: 100%;
            }
        }

        button {
            @include task-button;

            &.task-assignment {
                height: 48px;
                width: 102px;
                border-left: 2px solid $primary-grey-1;
                border-bottom-style: solid;
                border-bottom-width: 0;
                border-bottom-color: $primary-grey-1;
                background-image: unquote('url(Images/32px-icon_plus.svg)');
                background-position: left;
                text-align: left;
                padding-left: 32px;

                &.has-even-depth-parent {
                    border-left-color: $secondary-grey-2;
                    border-bottom-color: $secondary-grey-2;
                }

                &.with-icon--unpinned,
                &.with-icon--pinned,
                &.is-saving {
                    padding-left: 6px;
                    width: 50px;
                    background-position: center center;
                    text-align: center;
                }

                &.with-icon--unpinned {
                    background-image: unquote('url(Images/32px-icon_unpinned.svg)');
                }

                &.with-icon--pinned {
                    background-image: unquote('url(Images/32px-icon_pinned.svg)');
                }

                &.is-saving {
                    background-image: none;

                    &::after {
                        content: '';
                        display: inline-block;
                        animation: rotate .4s infinite linear;
                        border: 2px solid transparent;
                        border-top-color: $black;
                        border-left-color: $black;
                        border-radius: 50%;
                        margin-top: 11px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            &.task-visibility {
                position: sticky;
                float: left;
                background-color: inherit;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-image: unquote('url(Images/32px-icon_down.svg)');
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 48px;
                    height: 48px;
                    transform: rotate(-90deg);
                    transition: transform .5s;
                }

                &.unassigned-visible {
                    &::before {
                        transform: rotate(0deg);
                    }
                }
            }
        }

        @for $i from 1 through 12 {
            .depth-#{$i} {
                top: ($i - 1) * 48px !important;
                z-index: 13 - $i;
            }
        }

        .task-details {
            @include task-details-container;
            background-color: $secondary-grey-1;
            margin-left: $grid-size;
            width: calc(100% - (4 * #{$grid-size}) - #{$border-size});
            position: relative;
            cursor: pointer;
            color: $primary-yellow-1;

            &.last {
                margin-bottom: 0;
            }

            &.selected {
                button {
                    &.task-assignment {
                        height: 50px;
                        border-bottom-width: 2px;
                    }
                }
            }

            & + .project-hierarchy {
                margin-top: $grid-size;
            }

            .task-name {
                &,
                & > * {
                    font-family: $font-family-bold;
                    color: $primary-yellow-1;
                }
            }

            &.full-width {
                width: calc(100% - #{$grid-size});

                p {
                    &.left-column {
                        width: calc(66% - (#{$grid-size} / 2));
                    }

                    &.right-column {
                        width: calc(33% - (#{$grid-size} / 2));

                        &.shorter {
                            width: calc(33% - 50px);

                            &.shorter--with-text-button {
                                width: calc(33% - 102px);
                            }
                        }
                    }
                }
            }

            &.default-cursor {
                cursor: default;
            }

            p {
                display: inline-block;
                font-family: $font-family-light;
                vertical-align: top;
                padding-top: $grid-size - 3px;
                margin-bottom: $grid-size * .5;
                color: $primary-yellow-1;

                & > * {
                    color: $primary-yellow-1;
                }

                &.left-column {
                    width: calc(66% + (3 * #{$grid-size} / 2));
                }

                &.right-column {
                    width: calc(33% - (5 * #{$grid-size} / 2));
                }

                span {
                    &.label {
                        font-family: $font-family-light;
                    }
                }
            }

            .gang-assigned-hours-breakdown {
                position: relative;
                min-height: (3 * $grid-size);

                &::before {
                    content: '';
                    display: block;
                    border-top: 2px solid $secondary-grey-2;
                    margin-right: $grid-size;
                    margin-left: $grid-size;
                }
            }

            .loader {
                min-height: (3 * $grid-size);

                &:not(.translucent) {
                    background-color: transparent;

                    .spinner {
                        border-top-color: $secondary-grey-6;
                        border-left-color: $secondary-grey-6;
                    }
                }
            }
        }
    }

    .with-fixed-header {
        @for $i from 1 through 12 {
            .depth-#{$i} {
                top: (($i - 1) * (3 * $grid-size)) + (8 * $grid-size);
                z-index: 13 - $i;

                &.reset-sticky {
                    top: (($i - 1) * (3 * $grid-size)) !important;
                }
            }
        }
    }

    loader {
        z-index: 15;

        .loader {
            z-index: 15;
        }
    }
}
