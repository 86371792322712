﻿@import 'Variables';

.full-page-panel {
    padding: 0 32px;

    h2 {
        line-height: 65px;
        font-family: $font-family-ultra-light;
        font-size: $font-size-header;
        letter-spacing: -2px;
    }
}
