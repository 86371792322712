﻿@import 'Variables';

$icon-width: 80px;
$types: alert exit filter help key view;

.user-info {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: unquote('url(Images/' + $black-string + '/32px-icon_locked.svg)') no-repeat center center;
    cursor: pointer;
    line-height: 64px;
    width: 64px;
    height: 64px;
    white-space: normal;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 0;
        border-left: 1px solid $primary-grey-1;
        opacity: .4;
        width: 1px;
        height: auto;
    }

    .hover-panel {
        top: 64px;
        min-width: 209px;
        right: 0;
        cursor: default;
        height: auto;

        a,
        .link {
            min-width: 160px;
            white-space: nowrap;

            @each $type in $types {
                &.#{$type} {
                    &::before {
                        background-image: unquote('url(Images/' + $white-string + '/32px-icon_' + $type + '.svg)');
                    }
                }
            }
        }

        > .menu-item {
            position: relative;
            padding: 12px 32px 12px 40px;
            line-height: $copy-text-line-height;
            color: $white;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 48px;
                content: '';
            }

            &.user {
                margin-top: 8px;
                margin-bottom: 8px;

                &::before {
                    background: unquote('url(Images/' + $white-string + '/32px-icon_user.svg)') no-repeat left center;
                }
            }
        }

        > .panel-arrow {
            left: auto;
            right: 8px;
        }
    }
}
