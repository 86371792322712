﻿@import 'Variables';

.operatives-list-content,
.operatives-list-container,
.operatives-ownership-content {
    margin: 0 ($grid-size * 2) ($grid-size * 2);

    &.no-margin {
        margin: 0;
    }

    table {
        width: 100%;
        table-layout: fixed;

        tr {
            th,
            td {
                border-right: $border-size solid $white;
                border-bottom: $border-size solid $white;
                height: $grid-size * 3;

                &:last-child {
                    border-right: 0;
                }
            }

            th {
                background-color: $secondary-grey-1;
                color: $white;
                cursor: pointer;
                font-family: $font-family;
                text-align: left;
                padding: 0 ($grid-size / 2);
                height: $grid-size * 2;

                .sort-icon {
                    color: $white;
                }
            }

            td {
                background-color: $primary-grey-1;
                line-height: ($grid-size * 3) - $border-size;
                padding: 0 ($grid-size / 2);
                border-top: $border-size solid $white;
                border-bottom: $border-size solid $white;

                &.icon-borrowed {
                    background-image: unquote('url(Images/' + $white-string + '/32px-icon_borrow.svg)');
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &:first-child {
                    border-top: $border-size solid $white;
                    border-bottom: $border-size solid $white;
                }
            }

            &:first-child {
                td {
                    border-top: 0;
                }
            }

            &:last-child {
                td {
                    border-bottom: 0;
                }
            }

            &.selected {
                td {
                    background-color: $secondary-grey-1;
                }

                &:hover {
                    td {
                        background-color: $secondary-grey-1;
                    }
                }

                &:active {
                    &:hover {
                        td {
                            background-color: $secondary-grey-1;
                        }
                    }
                }
            }

            &.disabled {
                td {
                    background-color: $secondary-grey-5;
                }
            }
        }
    }
}

.operatives-list-content {
    table {
        tr {
            &.selected {
                td {
                    background-color: $secondary-grey-2;
                }

                &:hover {
                    td {
                        background-color: $secondary-grey-2;
                    }
                }

                &:active {
                    &:hover {
                        td {
                            background-color: $secondary-grey-2;
                        }
                    }
                }
            }
        }
    }
}
