﻿@import 'Variables';

.loader-min-height {
    min-height: $grid-size * 10;
}

.loader {
    display: block;
    position: relative;
    background-color: $white;
    line-height: 0;
    text-align: center;
    max-height: calc(100vh - 164px);
    min-height: 192px;
    z-index: 2;

    &.translucent {
        opacity: .8;
    }

    &.transparent {
        background-color: transparent;
    }

    &.dynamic-height {
        min-height: unset;
    }

    .spinner {
        display: inline-block;
        animation: rotate .4s infinite linear;
        border: 4px solid transparent;
        border-top-color: $black;
        border-left-color: $black;
        border-radius: 50%;
        width: 32px;
        height: 32px;
    }

    &.pinned {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto;
        height: auto;
        max-height: unset;
        min-height: $grid-size * 10;

        &.button-loader {
            left: auto;
            right: 2px;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 16px;
            width: 32px;
            height: 32px;
        }

        &.for-listing {
            top: ($grid-size * 6);

            &.double-header-row {
                top: ($grid-size * 4) - 1;
            }
        }

        .spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -16px 0 0 -16px;
        }
    }

    &.large {
        .spinner {
            border-width: 8px;
            width: 64px;
            height: 64px;
        }

        &.pinned {
            .spinner {
                margin: -32px 0 0 -32px;
            }
        }
    }

    &.small {
        .spinner {
            border-width: 3px;
            width: 24px;
            height: 24px;
        }

        &.pinned {
            .spinner {
                margin: -12px 0 0 -12px;
            }
        }
    }
}

@keyframes rotate {
    from {
        -o-transform: rotate(0);
        transform: rotate(0);
    }

    to {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
