.modalWrapper {
  text-align: center;
  max-width: 405px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  .modalTitle {
    font-size: 24px;
    text-align: center;
    font-family: 'DINOffcPro-Light';
  }

  .modalTitleUppercase {
    text-transform: uppercase;
  }

  .modalParagraph {
    font-family: 'DINNextLTPro-Regular';
    font-size: 14px;
    color: $secondary-grey-1;
    text-align: center;
  }

  .modalButtons {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .modalButton {
    padding: 16px 32px;
    font-size: 14px;
  }

  .modalCloseButton {
    margin-top: 16px;
    padding: 16px 24px;
  }
  .modalDeleteCloseButton {
    padding: 16px 24px;
    background: transparent;
    border: 1px solid $secondary-grey-2;
  }
}
