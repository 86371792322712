﻿.visually-hidden {
    position: absolute;
    padding: 0;
    margin: -1px;
    border: 0;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);

    &.focusable {
        &:active,
        &:focus {
            position: static;
            margin: 0;
            overflow: visible;
            width: auto;
            height: auto;
            clip: auto;
        }
    }
}

// Addresses safari specific rendering issue of tables
table caption.visually-hidden {
    position: static;
}
