@font-face {
  font-family: 'DINNextLTPro-UltraLight';
  font-style: normal;
  font-weight: 400;
  src: url('Fonts/32332A_3_0.eot'); /* IE9 Compat Modes */
  src: local(''), url('Fonts/32332A_3_0.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/32332A_3_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/32332A_3_0.woff') format('woff'),
    /* Modern Browsers */ url('Fonts/32332A_3_0.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DINNextLTPro-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('Fonts/32332A_2_0.eot'); /* IE9 Compat Modes */
  src: local(''), url('Fonts/32332A_2_0.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/32332A_2_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/32332A_2_0.woff') format('woff'),
    /* Modern Browsers */ url('Fonts/32332A_2_0.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DINNextLTPro-Light';
  font-style: normal;
  font-weight: 400;
  src: url('Fonts/32332A_1_0.eot'); /* IE9 Compat Modes */
  src: local(''), url('Fonts/32332A_1_0.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/32332A_1_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/32332A_1_0.woff') format('woff'),
    /* Modern Browsers */ url('Fonts/32332A_1_0.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DINNextLTPro-Bold';
  font-style: normal;
  font-weight: 400;
  src: url('Fonts/32332A_0_0.eot'); /* IE9 Compat Modes */
  src: local(''), url('Fonts/32332A_0_0.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/32332A_0_0.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/32332A_0_0.woff') format('woff'),
    /* Modern Browsers */ url('Fonts/32332A_0_0.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DINOffcPro-CondBold';
  font-style: normal;
  font-weight: 700;
  src: url('Fonts/DINOffcPro-CondBold.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('Fonts/DINOffcPro-CondBold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/DINOffcPro-CondBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/DINOffcPro-CondBold.woff')
      format('woff'),
    /* Modern Browsers */ url('Fonts/DINOffcPro-CondBold.ttf')
      format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'DINOffcPro-Light';
  font-style: normal;
  font-weight: 700;
  src: url('Fonts/DINOffcPro-Light.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('Fonts/DINOffcPro-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('Fonts/DINOffcPro-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('Fonts/DINOffcPro-Light.woff')
      format('woff'),
    /* Modern Browsers */ url('Fonts/DINOffcPro-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}
