﻿@import 'Variables';
@import 'Placeholder';

.task-assignment-content {
    margin-top: -2 * $grid-size;

    > section {
        display: block;
        margin: 0 (2 * $grid-size) (2 * $grid-size);
        position: relative;
    }
}

.search-tasks {
    position: absolute;
    right: 0;
}


@media screen and (max-width: 1136px) {
    task-tab-menu {
        &.collapsible {
            + .search-tasks {
                &.with-icon {
                    padding-left: 52px;
                    background-position: left -4px center;
                    padding-right: 12px;
                }
            }
        }
    }
}
