﻿@import '_Variables.scss';
@import 'Allocation.scss';

.week-selector {
    .date-picker {
        display: inline-block;
        vertical-align: middle;
        margin: 0 $grid-size * 2;
        height: 64px;
        font-size: 0;
        cursor: pointer;

        div {
            display: inline-block;
            vertical-align: middle;

            &.date-month-label {
                vertical-align: middle;
                height: 64px;
                line-height: 65px;
                font-family: $font-family-ultra-light;
                font-size: $font-size-header;
            }

            &.date-year-label {
                margin: 16px 0 16px 16px;
                line-height: 32px;
                height: 32px;
                border-bottom: 1px solid $black;
            }
        }

        .loader {
            &.pinned {
                min-height: 64px;
            }
        }
    }

    .week-navigator {
        display: inline-block;
        vertical-align: middle;

        &.previous {
            background-image: unquote('url(Images/' + $black-string + '/64px-icon_left.svg)');
        }

        &.next {
            background-image: unquote('url(Images/' + $black-string + '/64px-icon_right.svg)');
        }
    }
}

.ui-datepicker {
    td {
        &:not(.ui-datepicker-current-day) {
            .ui-state-default {
                @for $i from 0 through 100 {
                    &.date-allocation-percentage-#{$i} {
                        color: get-allocation-percentage-fg($i);
                        background-color: get-allocation-percentage-bg($i);

                        .color-blind-option & {
                            color: get-allocation-percentage-fg-color-blind($i);
                            background-color: get-allocation-percentage-bg-color-blind($i);
                        }
                    }
                }
            }
        }
    }
}
