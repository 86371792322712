﻿@import "Variables";
@import "Allocation.scss";

.react-datepicker {
  padding: 16px;
  width: 256px;
  background-color: #6c6c6c;
  z-index: 1000;
  position: relative;

  &__navigation {
    width: 2 * $grid-size;
    height: 2 * $grid-size;
    position: absolute;

    &--previous {
      left: 15px;
      background: unquote(
          "url(Images/" + $primary-yellow-1-string + "/32px-icon_left.svg)"
        )
        no-repeat;
    }

    &--next {
      right: 15px;
      background: unquote(
          "url(Images/" + $primary-yellow-1-string + "/32px-icon_right.svg)"
        )
        no-repeat;
    }
  }

  &__header {
    text-align: center;
    color: $primary-yellow-1;
    padding: 8px;
  }

  &__day-names,
  &__day-name,
  &__current-month {
    color: $primary-yellow-1;
  }

  &__day-names {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    margin-top: 8px;
    text-transform: lowercase;
  }

  &__month {
    text-align: center;
    white-space: nowrap;
  }

  &__day {
    color: $white;
    padding: 8px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: inline-block;

    &--today {
      background-color: $primary-grey-1;
      color: #000;
    }

    &--selected {
      background-color: #000;
      color: $white;
    }

    &:not(&--today) {
      @for $i from 0 through 100 {
        &.date-allocation-percentage-#{$i} {
          color: get-allocation-percentage-fg($i);
          background-color: get-allocation-percentage-bg($i);

          .color-blind-option & {
            color: get-allocation-percentage-fg-color-blind($i);
            background-color: get-allocation-percentage-bg-color-blind($i);
          }
        }
      }
    }
  }

  &__triangle {
    background: unquote("url(Images/panel-triangle_left.svg)") no-repeat;
    width: 16px;
    height: 32px;
    position: absolute;
    background-position: center;
    top: 18px;
    left: -15px;
  }
}
