.slick-dots{
    background-color: white;
}

.slick-prev:before, .slick-next:before{
    opacity: 0.0;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 0.0;
}