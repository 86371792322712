﻿@import 'Variables';

.header-items-menu {
    display: block;
    position: absolute;
    top: 32px;
    right: 32px;
    height: $grid-size * 4;
    padding: 0;
    font-size: 0;
    white-space: nowrap;
    z-index: 204;
    transition: opacity .15s linear;

    .right-panel-animating & {
        transition: none;
        opacity: 0;
    }

    .right-panel-visible & {
        .business-selector {
            display: none;
        }

        .notifications-center {
            &::after {
                display: none;
            }
        }
    }
}

.help-icon {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: unquote('url(Images/' + $black-string + '/32px-icon_help.svg)') no-repeat center center;
    cursor: pointer;
    line-height: 64px;
    width: 64px;
    height: 32px;
    white-space: normal;
    margin: 16px 0;
    border-right: 1px solid $secondary-grey-2;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 0;
        border-left: 1px solid $primary-grey-1;
        opacity: .4;
        width: 1px;
        height: auto;
    }
}