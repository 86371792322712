﻿@import 'Variables';
@import 'Placeholder';

.tooltip {
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 0.3s;
  color: $secondary-grey-1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.visible {
    opacity: 1;
  }
}

.assignment-tooltip--floating,
.ownership-tooltip--floating {
  position: absolute;
  top: -32px;
}

.project-settings,
.project-assignment-content,
.supervisor-ownership-content {
  margin: 0 (2 * $grid-size) (2 * $grid-size);

  table {
    table-layout: fixed;
    width: 100%;

    tr {
      border-bottom: $border-size solid $white;

      th,
      td {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }
  .project-settings-page {
    padding-bottom: 32px;
  }

  .project-settings-page,
  .project-listing,
  .user-listing,
  .user-project-listing,
  .super-user-listing,
  .super-user-supervisor-listing {
    tbody {
      tr {
        background-color: $primary-grey-1;

        &.selectable {
          cursor: pointer;

          &.selected,
          &:active {
            background-color: $secondary-grey-1;
          }
        }

        td {
          padding-left: $grid-size;
          background-color: transparent;
          line-height: 3 * $grid-size;
          min-height: 3 * $grid-size;
        }
      }
    }

    tr {
      margin-bottom: #{$border-size};

      th,
      td {
        border: 0;

        label {
          &[for] {
            cursor: pointer;
          }
        }
      }
    }
  }

  .user-project-listing,
  .super-user-supervisor-listing {
    margin-top: 32px;
  }

  .table-with-headers {
    th,
    td {
      &:not(:last-child) {
        border-right: $border-size solid $white;
      }
    }

    th {
      background-color: $secondary-grey-1;
      color: $white;
      text-align: left;
      padding: 0 ($grid-size / 2);
      height: $grid-size * 2;
      max-height: $grid-size * 2;
    }
  }

  .project-listing-content,
  .project-assignable-users-content,
  .superuser-supervisor-ownership-content {
    table {
      border-spacing: #{$border-size};

      col {
        &.fullname {
          width: 40%;
        }

        &.ad-username {
          width: 30%;
        }

        &.payroll-code {
          width: 30%;
        }
      }
    }

    .user-filter-panel,
    .ownership-header {
      position: relative;
      margin-bottom: 2 * $grid-size;

      .filter-controls-ctnr {
        position: relative;
      }

      .user-filter-textbox,
      .supervisor-filter-textbox {
        @include placeholder($secondary-grey-2);
        display: block;
        position: relative;
      }

      .reset-filter {
        position: absolute;
        top: 0;
        right: 0;
        width: 4 * $grid-size;
        height: 4 * $grid-size;
        cursor: pointer;
        background-image: url('Images/32px-icon_cross.svg');
        background-position: center;
        background-size: 90%;
      }
    }

    .search-btn {
      float: right;
      height: 64px;
      width: 64px;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: $primary-yellow-1;
      background-image: url('Images/64px-icon_search.svg');
      cursor: pointer;
      border: 0;
    }
  }

  .project-settings-content,
  .user-project-assignment-content,
  .superuser-supervisor-ownership-content {
    .assignment-header {
      &::after {
        clear: both;
        content: '';
        display: block;
      }

      .action-btn {
        float: right;
        margin-left: $grid-size;
      }
    }

    .ownership-header {
      display: grid;
      grid-template-columns: auto max-content max-content;
      grid-column-gap: $grid-size;

      &.no-cancel {
        grid-template-columns: auto max-content;
      }
    }

    .project-settings-page,
    .user-project-listing,
    .super-user-supervisor-listing {
      col {
        &.checkbox-column,
        &.superuser-assignment,
        &.supervisor-assignment,
        &.supervisor-ownership,
        &.display-detail,
        &.primary-detail {
          width: 3 * $grid-size;
        }

        &.assignment-label {
          width: 300px;
        }

        &.dropdown-column {
          width: 9 * $grid-size;
        }
      }

      th {
        height: 32px;
        max-height: 32px;

        &.superuser-assignment,
        &.supervisor-assignment,
        &.supervisor-ownership,
        &.display-detail,
        &.primary-detail {
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
        }

        &.focus {
          background-color: $secondary-grey-4;
        }

        &.supervisor-assignment {
          background-image: unquote('url(Images/fff/32px-icon_notepad.svg)');
        }

        &.superuser-assignment {
          background-image: unquote('url(Images/fff/32px-icon_key.svg)');
        }

        &.supervisor-ownership {
          background-image: unquote('url(Images/fff/32px-icon_user.svg)');
        }

        &.display-detail {
          background-image: unquote('url(Images/32px-icon_view.svg)');
        }

        &.primary-detail {
          background-image: unquote('url(Images/32px-icon_star.svg)');
        }

        &.assignment-tooltip-ctnr {
          padding-left: $grid-size;

          .tooltip {
            text-align: left;
            height: 32px;
            line-height: 32px;
          }
        }
      }

      td {
        &.checkbox-label {
          padding: 0 (0.5 * $grid-size);
          vertical-align: middle;

          input {
            &[type='checkbox'] {
              position: fixed;
              left: -100px;

              & + label {
                width: 2 * $grid-size;
                height: 2 * $grid-size;
                display: block;
                background-color: $white;
                background-repeat: no-repeat;
                border: 1px solid $border-color;
                background-position: center;
                background-size: cover;
                background-origin: border-box;
              }

              &:checked {
                & + label {
                  background-image: unquote('url(Images/32px-icon_tick.svg)');
                }
              }

              &:disabled {
                & + label {
                  background-color: $primary-grey-1;
                }
              }
            }
          }

          .checkbox-indent {
            margin-left: 32px;
          }
        }

        &.dropdown {
          padding: 0 (0.5 * $grid-size);
          vertical-align: middle;

          select {
            left: -100px;
            width: 8 * $grid-size;
            height: 2 * $grid-size;
            display: block;
            background-color: $white;
            background-repeat: no-repeat;
            border: 1px solid $border-color;
          }
        }
      }

      label {
        display: block;
      }

      .section-title {
        line-height: 2 * $grid-size;
        padding-left: $grid-size;
      }

      .checkbox-wrapper {
        height: 3 * $grid-size;
        padding: 0 8px;
        display: flex;
        gap: $grid-size;
        align-items: center;
        background: $primary-grey-1;
        margin-bottom: 2px;

        .checkbox-box {
          padding-right: 8px;
          input {
            &[type='checkbox'] {
              -webkit-appearance: none;
              appearance: none;
              background-color: #fff;
              margin: 0;
              font: inherit;
              color: currentColor;
              width: 2 * $grid-size;
              height: 2 * $grid-size;
              background-color: $white;
              background-repeat: no-repeat;
              border: 1px solid $border-color;
              transform: translateY(-0.075em);
              display: grid;
              place-content: center;
              cursor: pointer;

              &[type='checkbox']:checked::before {
                content: '';
                width: 2 * $grid-size;
                height: 2 * $grid-size;
                background-image: unquote('url(Images/32px-icon_tick.svg)');
              }

              &[type='checkbox']:disabled {
                background-color: $primary-grey-1;
                cursor: default;
              }

              &[type='checkbox']:disabled:checked::before {
                content: none;
                width: 2 * $grid-size;
                height: 2 * $grid-size;
                background-image: unquote('url(Images/32px-icon_tick.svg)');
              }

              &:disabled {
                & + label {
                  background-color: $primary-grey-1;
                }
              }
            }
          }
        }

        .checkbox-indent {
          margin-left: 32px;
        }
      }
    }
  }
}
