﻿@import 'Variables';

.durandal-wrapper > header {
    position: relative;
    top: 0;
    width: 100%;
    height: $header-height;
    z-index: 200;

    &.raised {
        z-index: 202;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 2 * $grid-size;
        background-image: url('Images/logo_96px.svg');
        width: 209px;
        height: 96px;
        background-repeat: no-repeat;
        z-index: 203;

        &__bylor {
            background-image: url('Images/logo__bylor.png');
            background-size: cover;
        }
    }

    .breadcrumb-menu-container {
        position: relative;
        margin-left: 257px;
        margin-right: 32px;
        background: $primary-grey-1;
        height: $header-height;
    }

    .main-menu-item {
        > a,
        > div {
            &:first-child {
                height: 64px;
            }
        }
    }
}
