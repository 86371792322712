﻿@import 'Variables';
@import 'Tasks';

$row-height: 3 * $grid-size;

.expand-anim-btn {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: unquote('url(Images/32px-icon_down.svg)');
        background-repeat: no-repeat;
        background-position: center;
        transform: rotate(0deg);
        transition: transform .5s;
        width: 100%;
        height: 100%;
    }

    &.open {
        &::before {
            transform: rotate(180deg);
        }
    }
}

.hierarchy-ctnr + .hierarchy-ctnr {
    margin-top: $grid-size / 8;
}

.project-task-assignments {
    .hierarchy-ctnr {
        &:first-child {
            margin-top: $grid-size / 8;
        }
    }
}

.hierarchy-ctnr {
    background-color: $secondary-grey-1;

    @for $i from 0 through 5 {
        > .hierarchy-lvl-#{$i} {
            margin-left: $grid-size * $i;
            max-width: calc(100% - (#{$grid-size} * #{$i}));
        }
    }
}

.with-left-border {
    border-left: 3px solid $secondary-grey-2;
}

.assignments-overview {

    .assignment {
        margin-left: 16px;

        .bold {
            font-family: $font-family-bold;
        }

        &.diff-gang {
            background-color: lighten($secondary-grey-1, 7%);

            .info-row,
            .task-toolbar {
                &.viewing-on-grid {
                    .view-on-grid-btn,
                    .remove-assignment-btn {
                        border-left-color: lighten($secondary-grey-1, 7%);
                    }
                }
            }
        }

        &.borrowed {
            .task-toolbar {
                &::before {
                    content: '';
                    display: inline-block;
                    margin-left: -8px;
                    margin-right: $grid-size / 2;
                    width: 32px;
                    height: 32px;
                    background-image: url('Images/ffec00/18px-cell-status_borrowed.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &.viewing-on-grid {
                    &::before {
                        background-image: url('Images/000/18px-cell-status_borrowed.svg');
                    }
                }
            }
        }

        .info-row,
        .task-toolbar {
            @include details-row;
            color: $primary-yellow-1;
            margin-bottom: 0;

            span,
            p {
                color: $primary-yellow-1;
            }
        }

        .info-row {
            &.with-columns {
                display: flex;
                flex-flow: row nowrap;

                .col {
                    @for $i from 1 through 100 {
                        &.col-#{$i} {
                            width: calc(#{$i} * 1%);
                        }
                    }

                    &.col-fixed-size {
                        flex: 0 0 auto;
                    }

                    &.col-fill {
                        flex: 1 1;
                    }
                }
            }

            &.multiline {
                line-height: 1.75 * $grid-size;
                padding-top: .75 * $grid-size;
                padding-bottom: .75 * $grid-size;
            }

            &.comment {
                overflow-y: visible;

                .comment-label,
                .comment-text {
                    line-height: 1.5rem;
                }

                .comment-text {
                    padding: 0 4px;
                    white-space: pre-wrap;
                    word-break: break-word;
                }
            }
        }

        .task-toolbar {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &.viewing-on-grid {
                background-color: $primary-yellow-1;

                span {
                    color: $black;
                }

                .view-on-grid-btn,
                .remove-assignment-btn {
                    width: $row-height + 2;
                    border-left: 2px solid $secondary-grey-1;
                    margin-left: 0;
                }

                .assigned-percentage {
                    margin-right: $grid-size - 2px;
                }
            }

            .toolbar-section-right {
                margin-left: auto;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                height: $row-height;
            }

            .assigned-percentage {
                margin-right: $grid-size;
            }

            .view-on-grid-btn,
            .remove-assignment-btn {
                background-repeat: no-repeat;
                background-position: center;
                width: $row-height;
                height: $row-height;
            }

            .view-on-grid-btn {
                background-image: unquote('url(Images/32px-icon_view.svg)');
            }

            .remove-assignment-btn {
                margin-left: 2px;
                background-image: unquote('url(Images/32px-icon_cross.svg)');
            }
        }

        .days-visibility-btn,
        .other-ops-visibility-btn {
            float: right;
            width: $row-height;
            height: $row-height;
        }

        .extra-info-row {
            height: 100px;
        }
    }
}
