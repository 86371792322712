﻿@import 'Variables';

.rotate-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url('Images/illustration_rotation.svg');
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(90deg);
        transition: transform 2s;
    }

    &.rotate {
        &::before {
            transform: rotate(0deg);
        }
    }

    h1 {
        display: inline;
        position: fixed;
        top: 96px;
        padding: 0 10px;
        line-height: 48px;
        height: 48px;
        left: 64px;
        color: $white;
        text-transform: uppercase;
        background-color: $black;
    }

    p {
        position: fixed;
        top: 144px;
        left: 64px;
        right: 0;
    }
}
