﻿@import 'Variables';

$ratio: 1.61803398875;
$width: 674px;
$height: round($width / $ratio);
$modal-height: 88px;
$image-height: 247px;
$dots-height: $grid-size * 3;
$dots-width: $grid-size * 1.5;

.modal-panel,
.help-cards {
    $overlay-color-transparent: rgba(0, 0, 0, 0);
    $overlay-color-dimmed: rgba(0, 0, 0, .75);

    background-color: $overlay-color-transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color ease .75s;

    &.help-cards--overlay-dimmed,
    &.modal-panel--overlay-dimmed {
        background-color: $overlay-color-dimmed;
    }

    > .help-cards__container,
    > .modal-panel__container {
        flex: 0 1 auto;
        width: $width;
        height: $height;
        max-height: 80vh;
        position: relative;

        > .help-cards__frame,
        > .modal-panel__frame {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: $white;
            position: relative;
            display: flex;
            flex-direction: column;

            .help-card,
            .modal-panel {
                $card-height: $height - $dots-height;
                height: $card-height;
                width: $width;
                display: flex;
                flex-direction: column;

                .help-card__image {
                    height: $image-height;
                }

                .help-card__narrative {
                    height: $card-height - $image-height;
                    padding: $grid-size * 2;
                    font-size: 18px;
                }

                .modal-panel__narrative {
                    height: $modal-height;
                    padding: $grid-size * 2;
                    font-size: 18px;
                }
            }

            .slick-dots {
                display: block;
                height: $dots-height;
                text-align: center;
                margin: 0;
                padding: 0;

                > li {
                    display: inline-block;
                    width: $dots-width;
                    height: $dots-height;

                    > button {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        background: url('Images/help-cards/dot-inactive.svg') no-repeat center center;
                        font-size: 0;
                    }

                    &.slick-active {
                        > button {
                            background-image: url('Images/help-cards/dot-active.svg');
                        }
                    }
                }
            }
        }

        .slick-arrow {
            $arrow-width: $grid-size * 4;
            $arrow-offset: $grid-size * -6;

            position: absolute;
            display: block;
            width: $arrow-width;
            height: $arrow-width;
            top: ($height / 2) - ($arrow-width / 2);
            background-color: transparent;
            cursor: pointer;
            background-position: center center;
            background-repeat: no-repeat;
            font-size: 0;

            &.slick-prev {
                left: $arrow-offset;
                background-image: url('Images/fff/64px-icon-thicker_left.svg');
                transition: opacity ease .6s;

                &.slick-disabled {
                    cursor: default;
                    opacity: 0;
                }
            }

            &.slick-next {
                right: $arrow-offset;
                background-image: url('Images/fff/64px-icon-thicker_right.svg');

                &.slick-disabled {
                    visibility: hidden;
                }
            }

            &.help-cards__dismiss,
            &.modal-panel__dismiss {
                right: $arrow-offset;
                background-image: url('Images/fff/64px-icon-thicker_tick.svg');
                border-bottom: 0;
            }
        }

        > .modal-panel__skip,
        > .help-cards__skip {
            $skip-width: $grid-size * 8;
            $skip-height: $grid-size * 3;
            display: block;
            position: absolute;
            bottom: ($skip-height + $grid-size) * -1;
            width: $skip-width;
            height: $skip-height;
            line-height: $skip-height;
            left: calc(50% - #{$skip-width/2});
            color: $white;
            text-decoration: none;
            font-size: 18px;
            text-align: center;
            -webkit-tap-highlight-color: transparent;
        }
    }

    > .modal-panel__container {
        height: $modal-height;

        .slick-arrow {
            $arrow-width: $grid-size * 4;
            top: ($modal-height / 2) - ($arrow-width / 2);
        }
    }
}
