﻿@import 'Variables';
@import 'Allocation';

@mixin tick-icon($color-string) {
  background-image: unquote(
    'url(Images/' + $color-string + '/32px-icon_tick.svg)'
  );
}

@mixin cell-icons($color-string) {
  &.borrowed {
    background-image: unquote(
      'url(Images/' + $color-string + '/18px-cell-status_borrowed.svg)'
    );
  }

  &.comment-or-delay {
    background-image: unquote(
      'url(Images/' + $color-string + '/18px-cell-status_comments.svg)'
    );
  }

  &.exception {
    background-image: unquote(
      'url(Images/' + $color-string + '/18px-cell-status_exception.svg)'
    );
  }
}

.operative-days-grid {
  table {
    width: 100%;
    table-layout: fixed;

    tr {
      td {
        border: $border-size solid $white;
        padding: 0;

        &:first-child {
          border-left: 0;
        }

        &:last-child {
          border-right: 0;
        }
      }

      &:first-child {
        td {
          border-top: 0;
        }
      }
    }

    &.sticky-header-table {
      top: $grid-size * 8;

      tr {
        td {
          background-color: transparent;
          border-bottom: 0;
          border-top: 0;

          &.header-day {
            height: 33px;
            padding: 0 8px;
            color: $white;
            background-color: $secondary-grey-1;
          }

          &:not(:first-child) {
            border-bottom: $border-size solid $white;
          }
        }
      }
    }

    .clickable {
      cursor: pointer;
    }

    &.read-only {
      .clickable {
        cursor: default;
      }
    }
  }

  .content-table {
    .operative-row {
      .cell-content {
        line-height: $font-size-x-large;
        font-family: $font-family-light;
        overflow: hidden;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        height: 64px;
        width: 100%;

        .cell-hours-allocation {
          min-width: 40px;
        }

        .cell-event-info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 16px;
          flex: 1;

          .cell-event-title {
            font-size: $font-size-small;
            line-height: 18px;
            text-align: right;
            margin-left: auto;
          }
          .cell-events-amount {
            background-color: rgba(211, 211, 211, 0.7);
            font-size: $font-size-small;
            border-radius: 999px;
            min-width: 24px;
            width: 24px;
            height: 24px;
            text-align: center;
          }
        }
      }

      .operative-name-cell {
        background-color: $primary-grey-1;

        .cell-content {
          font-size: $font-size-x-large;
          font-family: $font-family-light;
        }

        &.borrowed {
          background-color: $secondary-grey-2;
          background-image: unquote(
            'url(Images/' + $black-string + '/32px-icon_borrow.svg)'
          );
          background-repeat: no-repeat;
          background-position: right ($grid-size / 2) top 4px;
          background-size: 30px;

          .cell-content {
            padding-right: ($grid-size * 2.5);
          }
        }
      }

      .operative-day-cell {
        position: relative;
        overflow: hidden;

        .cell-icon-container {
          width: 18px;
          height: 100%;

          .cell-icon {
            @include cell-icons($black-string);
            width: 18px;
            height: 18px;
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }

        .selected-circle-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .selected-circle {
          $size: 48px;
          transition: transform 0.3s;
          position: absolute;
          top: calc(50% - #{$size / 2});
          left: calc(50% - #{$size / 2});
          width: $size;
          height: $size;
          border-radius: $size;
          transform: scale(5);
          z-index: -1;
          backface-visibility: hidden;
          transform-style: preserve-3d;
          perspective: 1000px;
          background-position: center center;
          background-repeat: no-repeat;

          &.show {
            transform: scale(1);
          }

          &.with-tick {
            @include tick-icon($black-string);
          }
        }

        &.show-selected-circle {
          .selected-circle {
            z-index: 1;
          }

          &.show-selected-circle-fade-background {
            &,
            .color-blind-option & {
              background-color: $primary-grey-1;
            }

            &.allocation-percentage-none {
              .color-blind-option & {
                background-color: $white;
              }
            }
          }
        }

        &.allocation-percentage-none {
          background-color: $white;

          .selected-circle {
            background-color: $secondary-grey-2;

            .color-blind-option & {
              background-color: $color-blind-option-grey-lightest;
            }
          }
        }

        @for $i from 0 through 100 {
          &.allocation-percentage-#{$i} {
            $bg-color: get-allocation-percentage-bg($i);
            $color-blind-bg-color: get-allocation-percentage-bg-color-blind($i);

            background-color: $bg-color;

            .color-blind-option & {
              background-color: $color-blind-bg-color;
            }

            .selected-circle {
              background-color: $bg-color;

              .color-blind-option & {
                background-color: $color-blind-bg-color;
              }

              @if $i < 5 or $i > 95 {
                // TODO [AK, RC2.0] - Make centralised function to determine color string based on percentage
                &.with-tick {
                  @include tick-icon($white-string);

                  .color-blind-option & {
                    @include tick-icon($black-string);
                  }
                }
              }
            }

            span {
              color: get-allocation-percentage-fg($i);

              .color-blind-option & {
                color: get-allocation-percentage-fg-color-blind($i);
              }
            }

            &[data-cell-state='awaiting-selection-for-day-overview'],
            &[data-cell-state='awaiting-selection-for-task-assignment'] {
              .cell-icon-container {
                .cell-icon {
                  @if $i < 5 or $i > 95 {
                    // TODO [AK, RC2.0] - Make centralised function to determine color string based on percentage
                    @include cell-icons($white-string);
                  }
                }
              }
            }
          }
        }

        &[data-cell-state='not-selected-for-day-overview'],
        &[data-cell-state='operative-day-does-not-have-assignment'],
        &[data-cell-state='operative-day-has-assignment'] {
          &,
          .color-blind-option & {
            background-color: $primary-grey-1;

            span {
              color: $secondary-grey-6;
            }
          }

          .cell-icon-container {
            .cell-icon {
              &,
              .color-blind-option & {
                @include cell-icons($secondary-grey-6-string);
              }
            }
          }
        }

        &[data-cell-state='selected-for-day-overview'],
        &[data-cell-state='selected-for-task-assignment'] {
          &,
          .color-blind-option & {
            span {
              color: $secondary-grey-6;
            }
          }

          .cell-icon-container {
            .cell-icon {
              &,
              .color-blind-option & {
                @include cell-icons($secondary-grey-6-string);
              }
            }
          }
        }

        span {
          display: block;

          &.global-exception-percentage {
            display: none;
          }

          &.global-exception-percentage,
          &.hours-worked {
            font-size: $font-size-large;
            font-family: $font-family-light;

            .percentage-assigned {
              display: inline;
              font-size: $font-size-normal;
            }
          }
        }

        .color-blind-option-toggling-info {
          position: relative;

          span {
            position: absolute;
          }
        }
      }
    }

    &:not(.carousel-info-toggled) {
      .carousel-toggling-info {
        span {
          &.percentage-assigned {
            &.carousel-hide-until-toggled {
              display: none;
            }
          }
        }
      }
    }

    &.carousel-info-toggled {
      .carousel-toggling-info {
        span {
          &.delay {
            display: none;
          }

          &.percentage-assigned {
            &.carousel-hide-until-toggled {
              display: block;
            }
          }
        }
      }
    }

    &.is-showing-global-exception-percentages {
      .operative-row {
        .operative-day-cell {
          span {
            &.global-exception-percentage {
              display: block;
            }

            &.hours-worked {
              display: none;
            }
          }

          .cell-icon-container {
            display: none;
          }
        }
      }
    }

    &.without-transitions {
      .operative-row {
        .operative-day-cell {
          transition: none;

          .cell-content {
            transition: none;
          }

          .selected-circle {
            transition: none;
          }
        }
      }
    }
  }

  .empty-list {
    &.has-loaded-days-grid {
      padding-top: 0;
      padding-right: 0;
      height: 64px;
      line-height: 64px;
      width: calc(100% - 208px);
      float: right;
    }
  }
}

@media screen and (max-width: 1180px) {
  operative-days-grid {
    .show-selected-circle {
      .selected-circle {
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        height: 40px;
        width: 40px;
        border-radius: 40px;
      }
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    operative-days-grid .selected-circle {
      will-change: auto !important;
    }
  }
}
