﻿@import 'Variables';

button {
  cursor: pointer;
  border: 0;
  background-position: center center;
  background-repeat: no-repeat;
  text-transform: uppercase;

  &.show-more {
    padding: 24px 16px;
    font-size: 12px;
  }

  &.yellow {
    background-color: $primary-yellow-1;

    &:active {
      background-color: $primary-yellow-1-button-pressed;
    }
  }

  &.disabled {
    background-color: $secondary-grey-6 !important;
    opacity: 0.5;
    cursor: default;
  }

  &.large {
    height: 64px;
    line-height: 64px;
    vertical-align: middle;
    min-width: 64px;
    padding: 0 16px;
  }

  &.white-with-border {
    background-color: $white;
    border: 2px solid $secondary-grey-5;
    height: 64px;
    line-height: 60px;
    vertical-align: middle;
    min-width: 64px;
    padding: 0 16px;
    text-align: center;

    &.large {
      width: 80px;
    }
  }

  &.with-icon {
    background-position: left center;
    padding-left: 60px;
  }

  &.with-icon--plus {
    background-image: url('Images/64px-icon_plus.svg');
  }

  &.with-icon--search {
    background-image: url('Images/64px-icon_search.svg');
  }

  .disabled-icon-button {
    display: flex;
    height: 100%;
    width: 30px;
    align-items: center;
    justify-content: center;
  }

  .disabled-text-button {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
