.assigned-to {
  display: none;

  &-visible {
    display: block;
  }
}

.expand-anim-btn {
  visibility: hidden;
  opacity: 0;
  &:before {
    transform: rotate(0deg);

    transition: transform 0.5s ease;
  }

  &-visible {
    visibility: visible;
    opacity: 1;
  }

  &-reverse {
    &:before {
      transform: rotate(180deg);
    }
  }
}

.other-operative-assignments-expand-wrapper {
  display: none;
  opacity: 0;
  width: 100%;
  padding: 20px 20px;
  flex-direction: column;
  row-gap: 30px;
  transition: opacity 0.5s ease;
  line-height: 1;

  &-visible {
    display: flex;
    opacity: 1;

    span {
      color: #ffec00;
    }
  }
}
