﻿$font-family: DINNextLTPro-Regular, sans-serif;
$font-family-light: DINNextLTPro-Light, sans-serif;
$font-family-ultra-light: DINNextLTPro-UltraLight, sans-serif;
$font-family-bold: DINNextLTPro-Bold, sans-serif;
$font-family-offc: DINOffcPro-CondBold, sans-serif;
$grid-size: 16px;
$border-size: 2px;
$header-height: 96px;
$layout-min-width: 962px;
$copy-text-line-height: $grid-size * 1.5;

$font-size-normal: 16px;
$font-size-small: 14px;
$font-size-large: 20px;
$font-size-x-large: 24px;
$font-size-assignment: 32px;
$font-size-header: 65px;

$black: #000;
$black-string: str-slice('#{$black}', 2);

$white: #fff;
$white-string: str-slice('#{$white}', 2);

$primary-yellow-1: #ffec00;
$primary-yellow-1-button-pressed: #fff133;
$primary-yellow-1-string: str-slice('#{$primary-yellow-1}', 2);

$primary-red-1: #c00;
$primary-red-1-string: str-slice('#{$primary-red-1}', 2);

$secondary-red-1: #e10613;
$secondary-red-1-string: str-slice('#{$secondary-red-1}', 2);

$primary-green-1: #8dc53e;
$primary-green-1-string: str-slice('#{$primary-green-1}', 2);

$primary-grey-1: #f3f3f3;
$primary-grey-1-string: str-slice('#{$primary-grey-1}', 2);

$secondary-grey-1: #5a5a5a;
$secondary-grey-1-string: str-slice('#{$secondary-grey-1}', 2);

$secondary-grey-2: #ccc;
$secondary-grey-2-string: str-slice('#{$secondary-grey-2}', 2);

$secondary-grey-3: #6c6c6c;
$secondary-grey-3-string: str-slice('#{$secondary-grey-3}', 2);

$secondary-grey-4: #878787;
$secondary-grey-4-string: str-slice('#{$secondary-grey-4}', 2);

$secondary-grey-5: #ddd;
$secondary-grey-5-string: str-slice('#{$secondary-grey-5}', 2);

$secondary-grey-6: #d3d3d3;
$secondary-grey-6-string: str-slice('#{$secondary-grey-6}', 2);

$placeholder-color: $secondary-grey-3;
$validation-color: $primary-red-1;
$border-color: $secondary-grey-5;

$tap-highlight-colour: rgba(243, 243, 243, 0.4);
$toggle-box-shadow-color: rgba(0, 0, 0, 0.125);
$docked-box-shadow-color: rgba(0, 0, 0, 0.7);

$color-blind-option-grey-lightest: hsl(0, 0, 90);
$color-blind-option-grey-lighter: hsl(0, 0, 75);
$color-blind-option-grey-medium: hsl(0, 0, 60);
$color-blind-option-grey-darker: hsl(0, 0, 45);
$color-blind-option-grey-darkest: hsl(0, 0, 30);

@mixin overflow-scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

@mixin copy-text($line-height: $copy-text-line-height) {
  padding-top: 6px;
  line-height: $line-height;
}

@mixin copy-text-small {
  @include copy-text($line-height: 20px);
  font-size: 14px;
  font-weight: 500;
}

@mixin ui-text(
  $line-height: $grid-size * 2,
  $font-size: 18px,
  $font-weight: 400
) {
  padding-top: 0;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

@mixin ui-text-small($line-height: $grid-size * 2) {
  @include ui-text($line-height, $font-size: 14px, $font-weight: 500);
}
