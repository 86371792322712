﻿.flex-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &.justify-space-between {
        justify-content: space-between;
    }

    > .flex-fill {
        flex-grow: 2;
    }

    > .align-self-start {
        align-self: flex-start;
    }
}
