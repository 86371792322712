﻿@import 'Variables';

.toggle-button {
    .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;

        &:active,
        &.active {
            background-image: none;
            outline: 0;
            box-shadow: inset 0 3px 5px $toggle-box-shadow-color;
        }
    }

    .btn-default {
        color: $black;
        background-color: $white;
        border-color: $secondary-grey-2;

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $black;
            background-color: $primary-grey-1;
            border-color: darken($secondary-grey-2, 20%);
        }
    }

    .btn-info {
        color: $black;
        background-color: $primary-yellow-1;
        border-color: $secondary-grey-2;

        &:hover,
        &:focus,
        &:active,
        &.active {
            color: $black;
            background-color: $primary-yellow-1;
            border-color: $secondary-grey-2;
        }
    }

    .toggle {
        position: relative;
        overflow: hidden;
        border-radius: 0;

        input {
            &[type='checkbox'] {
                display: none;
            }
        }

        &.btn {
            min-width: 80px;
            min-height: 48px;
        }

        &.toggle-btn-wide {
            min-width: 106px;
        }

        &.off {
            background-color: $primary-grey-1;

            .toggle-group {
                left: -100%;
            }
        }
    }

    .toggle-group {
        position: absolute;
        width: 200%;
        top: 0;
        bottom: 0;
        left: 0;
        transition: left .35s;
        user-select: none;
    }

    .toggle-off {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        right: 0;
        margin: 0;
        border: 0;
        border-radius: 0;

        &.btn {
            padding-left: 24px;
        }
    }

    .toggle-on {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 50%;
        margin: 0;
        border: 0;
        border-radius: 0;

        &.btn {
            padding-right: 24px;
        }
    }

    .toggle-handle {
        position: relative;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
        width: 0;
        border-width: 0 1px;
        border-radius: 0;
    }
}
