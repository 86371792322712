﻿@import 'Variables';

$types: alert info tick trash view /*magnifying*/;

$icon-width: 80px;
$panel-width: 209px;

@mixin type-set($colour-string) {
    @each $type in $types {
        &.#{$type} {
            &::before {
                background-image: unquote('url(Images/' + $colour-string +'/32px-icon_' + $type + '.svg)');
            }
        }
    }
}

.notifications-center {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: unquote('url(Images/' + $black-string + '/32px-icon_bell.svg)') no-repeat center center;
    cursor: pointer;
    line-height: 64px;
    width: 64px;
    height: 32px;
    white-space: normal;
    margin: 16px 0;
    border-right: 1px solid $secondary-grey-2;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        left: 0;
        border-left: 1px solid $primary-grey-1;
        opacity: .4;
        width: 1px;
        height: auto;
    }

    &.alerting {
        background-image: unquote('url(Images/' + $black-string + '/32px-icon_bell.svg)');
    }

    .hover-panel {
        left: auto;
        right: -65px;
        cursor: auto;
        width: 260px;

        > .panel-arrow {
            left: auto;
            right: 71px;
        }
    }
}

.notification-options {
    a,
    .link {
        @include type-set($white-string);
    }
}

.notifications {
    margin: 0;
    padding: $grid-size 0 0;
    user-select: text;

    > li {
        position: relative;
        color: $white;
        padding: 0 32px 16px 40px;
        line-height: $copy-text-line-height;
        word-wrap: break-word;
        list-style-type: none;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: no-repeat left -4px;
            width: 40px;
            height: 32px;
        }

        @include type-set($white-string);

        &.clickable {
            cursor: pointer;
        }

        &.no-notifications,
        &.is-read {
            color: $white;

            a {
                color: $white;

                &::after {
                    background: unquote('url(Images/' + $secondary-grey-1-string + '/32px-icon_right.svg)') no-repeat -4px -4px;
                }
            }
        }

        a {
            color: $black;

            &::after {
                content: '';
                position: absolute;
                background: unquote('url(Images/' + $primary-grey-1-string + '/32px-icon_right.svg)') no-repeat -4px -4px;
                width: 24px;
                height: 24px;
            }
        }
    }
}

.notifications-panel {
    top: 48px;
    z-index: 1000;

    &.mini-panel {
        .notification-options {
            display: none;

            + hr {
                display: none;
            }
        }

        .is-read {
            display: none;
        }
    }

    &.pinned-for-scrolled-page {
        position: fixed;
        top: 16px;
        right: 32px;

        &.help-button-visible {
            right: 96px;
        }
    }
}

.notification-icon {
    @include type-set($white-string);
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: auto;
        background-repeat: no-repeat;
        background-position: -7px center;
        content: '';
    }
}

.listing {
    .notification-icon {
        @include type-set($black-string);
    }
}
