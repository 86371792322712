﻿@import 'Variables';

.operatives-ownership-content {
    margin-top: $grid-size * -2;
    margin-bottom: 0;

    .header-container {
        position: sticky;
        top: 0;
        z-index: 10;
    }

    .actions-container {
        display: flex;
        padding-bottom: $grid-size * 2;
        background-color: $white;
        padding-top: $grid-size * 2;

        .spacer {
            flex: 1;
        }

        .search {
            flex: 1;
        }

        .button-help-text {
            width: auto;
        }

        button {
            &.fixed-width {
                width: $grid-size * 8;
            }

            &.icon-button {
                width: $grid-size * 4;

                &.borrow-icon {
                    background-image: url('Images/64px-icon_borrow-operative.svg');
                }

                &.remove-borrowed-operative-icon {
                    background-image: url('Images/64px-icon_remove-borrowed-operative.svg');
                }

                &.take-ownership-icon {
                    background-image: url('Images/64px-icon_take-ownership.svg');
                }

                &.search-icon {
                    background-image: url('Images/64px-icon_search.svg');
                }
            }
        }
    }

    table {
        td {
            &.supervisor-icon {
                background-image: url('Images/32px-icon_notepad.svg');
                background-repeat: no-repeat;
                padding-left: 32px;
                background-position: 0 center;
            }
        }

        tr {
            &.selectable {
                &:active {
                    td {
                        &.supervisor-icon {
                            background-image: url("Images/#{$white-string}/32px-icon_notepad.svg");
                        }
                    }
                }
            }

            &.selected {
                td {
                    &.supervisor-icon {
                        background-image: url("Images/#{$white-string}/32px-icon_notepad.svg");
                    }
                }
            }
        }
    }

    .pager {
        .pager-button {
            &.previous-page {
                background-color: $secondary-grey-1;
                background-image: url('Images/#{$white-string}/64px-icon_left.svg');
            }

            &.next-page {
                background-color: $secondary-grey-1;
                background-image: url('Images/#{$white-string}/64px-icon_right.svg');
            }

            &.current-page {
                background-color: $secondary-grey-1;
                color: $white;
            }
        }
    }

    .button-array {
        height: $grid-size * 2;
    }

    .listing-pager {
        position: sticky;
        bottom: -2 * $grid-size;
        z-index: 10;
    }
}

.ownership-panel {
    position: fixed;
    height: 320px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 15;
    padding-top: 16px;
    overflow-y: hidden;

    &.docked-panel {
        height: 320px;
    }

    &.as-relative {
        position: relative;
        top: 0;
        height: auto;
        padding-top: 0;
        margin-top: -16px;
    }

    h1 {
        &.task-name {
            font-family: $font-family-ultra-light;
            height: $grid-size * 5;
            font-size: $font-size-header;
            letter-spacing: -2px;
            text-transform: uppercase;
        }
    }

    .action-buttons {
        padding-top: 5px;

        button {
            margin-left: 16px;
            text-transform: uppercase;
        }
    }
}

.operatives-ownership-empty-listing-spacer {
    height: calc(100vh - (16px * 20));
}