﻿@import 'Variables';
@import 'Placeholder';

.search {
    .search-header {
        height: 4 * $grid-size;
        margin-bottom: 2 * $grid-size;
    }

    .search-textbox {
        @include placeholder($secondary-grey-2);

        display: inline-block;
        width: calc(100% - 96px);
    }

    .cancel {
        float: right;
        width: 80px;

        &.float-left {
            float: left;
            margin-right: 16px;
        }
    }
}
