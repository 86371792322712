﻿@import 'Variables';

.assignment-removal-options-panel {
    position: fixed;
    box-sizing: border-box;
    height: auto;
    max-height: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 500;
    padding: 0 32px;
    transition: max-height .5s;

    .stacked-button {
        display: block;
        width: 480px;
        max-width: 100%;
        margin: auto;
        margin-bottom: 16px;

        &:first-child {
            margin-top: 16px;
        }

        &:last-child {
            margin-bottom: 17px;
        }
    }

    &.open {
        max-height: 100vh;
    }
}

.assignment-panel {
    position: fixed;
    height: calc(100vh - 96px);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 15;
    padding-top: 16px;
    overflow-y: hidden;

    .percentage-assigned-label {
        transition: opacity .5s linear;
        opacity: 0;

        &.visible {
            opacity: 1;
        }
    }

    &.docked-panel {
        height: 320px;

        &.with-validation {
            height: 420px;
        }
    }

    &.with-transition {
        transition: height .5s;
    }

    &.as-relative {
        position: relative;
        top: 0;
        height: auto;
        padding-top: 0;
        margin-top: -16px;
    }

    h1 {
        &.task-name {
            font-family: $font-family-light;
            height: 64px;
            line-height: 64px;
            padding-top: 5px;
        }
    }

    .action-buttons {
        padding-top: 5px;

        button {
            margin-left: 16px;
            text-transform: uppercase;
        }
    }

    fieldset {
        .field {
            .field-holder {
                &.delay-reasons-listing {
                    margin-bottom: 0;
                }
            }
        }

        .field-label--delay-toggle-or-reason {
            padding-top: 13px;
            padding-bottom: 13px;
        }

        .field-label--allocation-percentage {
            padding-top: 14px;
            padding-bottom: 14px;
        }

        .field--delay-toggle-or-reasons {
            margin-top: 12px;
        }
    }
}
