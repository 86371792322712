﻿@import 'Variables';

.breadcrumb-menu {
    display: inline-block;
    margin: 32px 0 0 16px;
    width: calc(100% - 208px);
    white-space: nowrap;

    .main-menu-item {
        padding-right: 52px;
        margin-left: -16px;
        max-width: 40%;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(:last-child) {
            max-width: none;

            @media only screen and (max-width: 1136px) {
                max-width: 400px; // Max feasible width to fit 1 full menu item (this), 3 truncated items + 3 menu buttons on Galaxy Active Tab
            }
        }

        &:last-child {
            padding-right: 0;

            &::after {
                display: none;
            }
        }

        > a,
        > div {
            &:first-child {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $black;
                text-transform: uppercase;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 4px;
            background: unquote('url(Images/' + $black-string + '/64px-icon_right.svg)') no-repeat center center;
            opacity: .2;
            width: 40px;
            height: 64px;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &.collapsed {
            > a,
            > div {
                &:first-child {
                    width: 0;
                }
            }
        }

        > .menu-panel {
            > div {
                &:first-child {
                    min-width: 170px;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}

.no-touch {
    .breadcrumb-menu {
        .main-menu-item {
            &:hover {
                > a,
                > div {
                    &:first-child {
                        opacity: .6;
                    }
                }
            }

            &.with-icon {
                &:hover {
                    &::before {
                        opacity: .6;
                    }
                }
            }
        }
    }
}
